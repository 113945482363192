<template>
    <div v-resize="onResize" class="ma-0 pa-0" :style="`height: ${height}px`">
        <v-banner sticky class="ma-0 pa-0" color="white" single-line>
            <v-text-field
                class="mb-2"
                sticky
                v-model="search"
                dense
                filled
                @keydown.space="findTags"
                @input="findTags"
                @change="findTags"
                @keydown.enter="findTags"
                hide-details="auto"
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                rounded
            />
        </v-banner>
        <v-row
            v-if="loadingBottom"
            class="mt-0"
            align="center"
            justify="center"
        >
            <v-progress-linear color="primary" indeterminate />
        </v-row>
        <v-row
            v-if="tool"
            id="scroll-target"
            v-scroll:#scroll-target="onScroll"
            class="overflow-y-auto mt-1"
            :style="`height: ${height - 58}px`"
        >
            <viewer
                v-if="firstLoaded"
                :images="filteredImages"
                @inited="inited"
                class="viewer"
                ref="viewer"
                rebuild
            >
                <img
                    class="image"
                    v-for="src in filteredImages"
                    :key="src.image320x320URL"
                    :src="src.image320x320URL"
                    :alt="src.imageURL ? src.imageURL : src.image320x320URL"
                />
            </viewer>
            <v-col
                v-for="(image, index) in search
                    ? commercialImages
                    : filteredImages"
                :key="image.id"
                :class="[image.show ? 'd-flex child-flex' : 'd-none']"
                cols="6"
                xs="6"
                sm="6"
                lg="3"
            >
                <ToolImageCard
                    :image="image"
                    :index="index"
                    :totalImages="commercialImages.length"
                    :loading="loading"
                    @downloadFile="downloadFile"
                    @deleteImage="deleteImage"
                    @updateImage="updateImage"
                    @removeTag="removeTag"
                    @editTags="editTags"
                    @show="show"
                    @firstLoad="firstLoad"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ToolImageCard from '@/components/Tools/ToolImageCard'
import { storage } from '@/services/firebase'
import API from '@/services/api'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
    name: 'CommercialImages',
    props: {
        tool: Object,
    },
    components: {
        ToolImageCard,
    },
    data() {
        return {
            folder: 'photo_galery',
            company: JSON.parse(localStorage.getItem('company')),
            item: { title: 'Prueba' },
            transparent: 'rgba(255, 255, 255, 0)',
            loading: false,
            search: '',
            deleteError: false,
            error: false,
            errorMsg: null,
            imageToDelete: null,
            deleteDialog: false,
            editDialog: false,
            tag: '',
            imageToUpdate: {},
            height: 0,
            index: 0,
            images: [],
            pagination: 1,
            folderImages: 'photo_galery',
            uploadedData: false,
            numberItems: 20,
            loadingBottom: false,
            firstLoaded: false,
        }
    },
    watch: {
        tool: async function() {
            await this.setShow()
        },
    },
    async created() {
        if (this.tool) {
            await this.setShow()
        }
    },
    computed: {
        ...mapState(['commercialImages']),
        filteredImages: function() {
            return this.commercialImages.slice(
                0,
                this.pagination * 20 <= this.commercialImages.length
                    ? this.pagination * 20
                    : this.commercialImages.length
            )
        },
    },
    methods: {
        ...mapActions([
            'addToolsAction',
            'setCommercialImagesAction',
            'addCommercialImageAction',
            'delCommercialImageAction',
            'resetComercialImagesAction',
        ]),
        ...mapMutations(['setErrorItems']),
        openDelete(image) {
            this.deleteDialog = true
            this.imageToDelete = image
        },
        inited(viewer) {
            this.$viewer = viewer
        },
        firstLoad() {
            this.firstLoaded = true
        },
        async setShow() {
            try {
                this.loadingBottom = true
                this.resetComercialImagesAction()
                const {
                    data: { commercialImages },
                } = await API.getCommercialImages()

                for (let image of commercialImages) {
                    image.show = true
                    this.addCommercialImageAction(image)
                }
                this.uploadedData = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingBottom = false
            }
        },
        findTags() {
            if (this.search) {
                const regex = new RegExp(this.search.toLowerCase(), 'gi')
                this.commercialImages.forEach(image => {
                    const images = image.tags.filter(tag => tag.match(regex))

                    if (images.length > 0) {
                        image.show = true
                    } else {
                        image.show = false
                    }
                })
            } else {
                this.commercialImages.forEach(image => (image.show = true))
            }
        },
        downloadFile(image) {
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = () => {
                var blob = xhr.response
                var fileUrl = URL.createObjectURL(blob)
                var fileLink = document.createElement('a')
                fileLink.href = fileUrl
                fileLink.setAttribute('download', image.file)
                document.body.appendChild(fileLink)
                fileLink.click()
            }
            xhr.open('GET', image.imageURL)
            xhr.send()
        },
        show(index) {
            this.firstLoad = true
            this.$viewer.index = index
            this.$viewer.show()
        },
        deleteOnStorage: async function(file) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(file)
            await refDoc.delete()
            return true
        },
        async deleteImage(image) {
            this.imageToDelete = image
            await this.upload()
        },
        upload: async function() {
            try {
                const index = this.commercialImages.indexOf(this.imageToDelete)
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.deleteCommercialImage(this.commercialImages[index].id)
                await this.deleteOnStorage(this.imageToDelete.file)
                await this.deleteOnStorage(this.imageToDelete.file320x320)
                this.delCommercialImageAction(index)
                this.deleteDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        editTags: function(image) {
            this.imageToUpdate = image
            this.editDialog = true
        },
        updateImage: async function(index, image) {
            try {
                this.imageToUpdate = image
                this.loading = true
                this.error = false
                this.errorMsg = ''
                await API.updateCommercialImage(
                    this.commercialImages[index].id,
                    { tags: this.imageToUpdate.tags }
                )
                this.setCommercialImagesAction({
                    index,
                    image: this.imageToUpdate,
                })
                this.editDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addTag(event) {
            event.preventDefault()
            var val = event.target.value.trim().toLowerCase()
            if (val.length > 0) {
                this.imageToUpdate.tags.push(val)
                event.target.value = ''
                this.tag = ''
            }
        },
        removeTag(index) {
            this.imageToUpdate.tags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
                this.removeTag(this.imageToUpdate.tags.length - 1)
            }
        },
        onScroll: async function(e) {
            let bottomOfWindow =
                e.target.scrollHeight - Math.abs(e.target.scrollTop)
            if (bottomOfWindow < e.target.clientHeight + 48) {
                this.pagination += 1
            }
        },
        async loadImages(image) {
            const path = `${this.company}/${this.folderImages}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(image.file)
            const profilePic320x320Ref = storageRef.child(image.file320x320)
            await profilePicRef
                .getDownloadURL()
                .then(url => {
                    image.imageURL = url
                })
                .catch(error => {
                    image.imageURL = ''
                    console.log(error)
                })
            await profilePic320x320Ref
                .getDownloadURL()
                .then(url => {
                    image.image320x320URL = url
                })
                .catch(error => {
                    image.image320x320URL = ''
                    console.log(error)
                })
        },
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 173
        },
    },
}
</script>
<style scoped>
.v-card {
    transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.9;
}

.show-btns {
    color: #0277bd !important;
}
.image {
    display: none;
}
</style>
